import React from 'react';
import { useState, ReactNode } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
} from '@mui/material';
import styled from 'styled-components';

import CoverImage from '@/images/epub/self-affirmation/cover.png';
import Example09Image from '@/images/epub/self-affirmation/example-09.png';
import Example13Image from '@/images/epub/self-affirmation/example-13.png';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import PlayBooksIcon from "@/images/epub/common/play_books_icon.svg";
import Yes24Icon from "@/images/epub/common/yes24_icon.svg";
import Link from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";

const SelfAffirmationView = () => {

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<ReactNode>(<DialogTitle />);
  const [dialogContent, setDialogContent] = useState<ReactNode>(<DialogContent />);

  return (<div>
    <h1 className={'mb-5'}><span className="mb-4 pr-3 text-3xl font-bold leading-normal">내 삶을 풍요롭게 하는! 『영어로 하는 미쁨 자기긍정확언』 전자책</span>
    </h1>
    <p className='flex justify-center'>
      <img className="border-2 w-1/3" src={CoverImage} alt={'CoverImage'} />
      <img className="border-2 w-1/3" src={Example09Image} alt={'Example09Image'} />
      <img className="border-2 w-1/3" src={Example13Image} alt={'Example13Image'} />
    </p>
    <p className='flex justify-center'>
      <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
      <Link href="https://www.yes24.com/Product/Goods/124718933"><span>예스24 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>

      <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
      <Link href="https://play.google.com/store/books/details?id=jHnqEAAAQBAJ"><span>Play북 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>
    </p>

    <p className="mt-4 text-lg">
      영어를 공부할 때는 문장을 외우는 것이 좋은 방법입니다.<br/>
      영어 문장을 암기하며, 자존감을 키울 수 있도록<br/>
      나를 긍정해 주는 확언 위주로 구성하면 좋겠다는 생각에<br/>
      이 책을 편찬하게 되었습니다.
    </p>
    <p className="mt-4 text-lg">
      안드로이드 Play 북 기능인 "소리내어 읽기" 기능을 사용하면,<br/>
      편하게 반복하여 명상할 수 있고,<br/>
      영어에 익숙해짐과 동시에<br/>
      삶이 풍요로워지는 것을 느낄 수 있습니다.
    </p>
    <p className="mt-4 text-lg indent-2">
      『영어로 하는 미쁨 자기긍정확언』 전자책은 <b>TTS</b>(Text-To-Speech)를 활용하여 쉽게 들으며
      반복학습을 할 수 있도록 하였습니다.
    </p>

    <h1 className={'mt-5'}><span className="mb-4 pr-3 text-3xl font-bold leading-normal">TTS 시연</span></h1>
    <p className='flex justify-center'>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/skuAUpHGh0k?si=ztq9QWKegAwsEUgn"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <br/>
    </p>
    <p className='flex justify-center'>안드로이드 Play북 앱에서 『영어로 하는 미쁨 자기긍정확언』 책 TTS 시연 영상</p>

    <p className="mt-4 text-lg indent-2">
      TTS를 활용하면 쉽게 『미쁨1문1답 영문 전치사』 전자책을 쉽게 들으며 반복학습을 할 수 있도록 하였습니다. 다만, 플랫폼 별로 TTS 방식이 다르니 이점 유의 바랍니다.
    </p>

    <p className="mt-4 text-lg indent-2">
      플랫폼 별 TTS 시연 보기
      <ul>
        <li>- <Link component="button" underline="hover" onClick={() => {
          setDialogTitle(getYes24TTSDialogTitle());
          setDialogContent(getYes24TTSDialogContent());
          setDialogOpen(true);
        }}>{'예스24 TTS 시연'} </Link> <span className={'text-sm'}>(안드로이드와 아이폰 TTS 기능 차이 없음)</span></li>
        <li>- <Link component="button" underline="hover" onClick={() => {
          setDialogTitle(getAndroidPlayBookTTSDialogTitle());
          setDialogContent(getAndroidPlayBookTTSDialogContent());
          setDialogOpen(true);
        }}>{'안드로이드 Play북 TTS 시연'} </Link> <span className={'text-sm'}>(안드로이드의 글자읽어주기 기능 활용)</span></li>
        <li>- <Link component="button" underline="hover" onClick={() => {
          setDialogTitle(getIphonePlayBookTTSDialogTitle());
          setDialogContent(getIphonePlayBookTTSDialogContent());
          setDialogOpen(true);
        }}>{'아이폰 Play북 TTS 시연'} </Link> <span className={'text-sm'}>(아이폰의 화면읽어주기 기능 활용. 페이지별 화면넘기기로 보기 불가)</span></li>
      </ul>
    </p>

    <p className="mt-4 text-lg indent-2">
      아래 다음 카페 게시글로 더 많은 내용 확인할 수 있습니다.
    </p>

    <List>
      <ListItem key='home' disablePadding className={'border-t-4 border-b-2'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getBookReleasedDialogTitle());
          setDialogContent(getBookReleasedDialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『영어로 하는 미쁨 자기긍정확언』 출시
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              미쁨에듀를 이용해주셔서 감사합니다. 『영어로 하는 미쁨 자기긍정확언』 전자책이 출시되었습니다. ...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>
      <ListItem key='yes24' disablePadding className={'border-t-2 border-b-2'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getYes24DialogTitle());
          setDialogContent(getYes24DialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『영어로 하는 미쁨 자기긍정확언』 예스24 eBook 도움말
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              이 게시글은 『영어로 하는 미쁨 자기긍정확언』 전자책을 예스24 eBook에서 구매해서 사용하는 이용자를 위한 도움말입니다...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>
      <ListItem key='android-playbook' disablePadding className={'border-t-2 border-b-2'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getAndroidPlayBookDialogTitle());
          setDialogContent(getAndroidPlayBookDialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『영어로 하는 미쁨 자기긍정확언』 안드로이드 Play북 도움말
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              이 게시글은 『영어로 하는 미쁨 자기긍정확언』 전자책을 안드로이드 Play 북에서 구매해서 사용하는 이용자를 위한 도움말입니다...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>
      <ListItem key='iphone-playbook' disablePadding className={'border-t-2 border-b-4'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getIphonePlayBookDialogTitle());
          setDialogContent(getIphonePlayBookDialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『영어로 하는 미쁨 자기긍정확언』 아이폰 Play북 도움말
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              이 게시글은 『영어로 하는 미쁨 자기긍정확언』 전자책을 아이폰 Play 북에서 구매해서 사용하는 이용자를 위한 도움말입니다...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>

    </List>

    <Dialog open={dialogOpen}
            fullScreen={true}
            sx={{pr: 0, pl: 1}}
            PaperProps={{style: {minHeight: '90%', maxHeight: '90%'}}}>
      <TitleContainer>
        <ArrowBackIosNewIcon sx={{mr: 0}} onClick={() => setDialogOpen(false)}/>
        {dialogTitle}
      </TitleContainer>
      {dialogContent}
    </Dialog>
  </div>)
}


const getBookReleasedDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
    워드프레스 관련 게시글 보기
    <IconButton className={"MyCustomButton"}>
      <OpenInNew sx={{fontSize: 15}}
                 onClick={() => window.open("https://mippum.wordpress.com/2024/07/08/self-affirmation-release", '_blank')}/>
      <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/08/self-affirmation-release'}>https://mippum.wordpress.com/2024/07/08/self-affirmation-release</a>
    </IconButton>
  </DialogTitle>
};

const getBookReleasedDialogContent = () => {
  return <DialogContent sx={{'padding': '5px'}}>
    <iframe src="https://mippum.wordpress.com/2024/07/08/self-affirmation-release"
            className={'border-2'}
            width={'100%'}
            height={'100%'}
            title="W3Schools Free Online Web Tutorials">
    </iframe>
  </DialogContent> };

const getAndroidPlayBookDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
    워드프레스 관련 게시글 보기
    <IconButton className={"MyCustomButton"}>
      <OpenInNew sx={{fontSize: 15}}
                 onClick={() => window.open("https://mippum.wordpress.com/2024/07/10/self-affirmation-android-playbook", '_blank')}/>
      <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/10/self-affirmation-android-playbook'}>https://mippum.wordpress.com/2024/07/10/self-affirmation-android-playbook</a>
    </IconButton>
  </DialogTitle>
};

const getAndroidPlayBookDialogContent = () => {
  return <DialogContent sx={{'padding': '5px'}}>
    <iframe src="https://mippum.wordpress.com/2024/07/10/self-affirmation-android-playbook"
            className={'border-2'}
            width={'100%'}
            height={'100%'}
            title="W3Schools Free Online Web Tutorials">
    </iframe>
  </DialogContent> };

const getIphonePlayBookDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
    워드프레스 관련 게시글 보기
    <IconButton className={"MyCustomButton"}>
      <OpenInNew sx={{fontSize: 15}}
                 onClick={() => window.open("https://mippum.wordpress.com/2024/07/10/self-affirmation-ios-playbook", '_blank')}/>
      <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/10/self-affirmation-ios-playbook'}>https://mippum.wordpress.com/2024/07/10/self-affirmation-ios-playbook</a>
    </IconButton>
  </DialogTitle>
};

const getIphonePlayBookDialogContent = () => {
  return <DialogContent sx={{'padding': '5px'}}>
    <iframe src="https://mippum.wordpress.com/2024/07/10/self-affirmation-ios-playbook"
            className={'border-2'}
            width={'100%'}
            height={'100%'}
            title="W3Schools Free Online Web Tutorials">
    </iframe>
  </DialogContent> };

const getYes24DialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
    워드프레스 관련 게시글 보기
    <IconButton className={"MyCustomButton"}>
      <OpenInNew sx={{fontSize: 15}}
                 onClick={() => window.open("https://mippum.wordpress.com/2024/07/10/self-affirmation-yes24", '_blank')}/>
      <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/10/self-affirmation-yes24'}>https://mippum.wordpress.com/2024/07/10/self-affirmation-yes24</a>
    </IconButton>
  </DialogTitle>
};

const getYes24DialogContent = () => {
  return <DialogContent sx={{'padding': '5px'}}>
    <iframe src="https://mippum.wordpress.com/2024/07/10/self-affirmation-yes24"
            className={'border-2'}
            width={'100%'}
            height={'100%'}
            title="W3Schools Free Online Web Tutorials">
    </iframe>
  </DialogContent> };

const getYes24TTSDialogTitle = () => { return <DialogTitle sx={{fontWeight: 'bold'}}>
  예스24 TTS 시연
</DialogTitle> };

const getYes24TTSDialogContent = () => { return <DialogContent>
    <p className='flex justify-center'>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/gXpyDeEWhY0?si=kOtWYbKzYy0Sds2h"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </p>
    <p className='flex justify-center'>
      <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
      <Link href="https://www.yes24.com/Product/Goods/124718933"><span>예스24 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>
    </p>
    <p className={'mt-4 text-lg indent-2'}>
      예스24 eBook 앱은 자체 TTS 를 사용하기 때문에, 안드로이드와 아이폰의 차이가 없습니다.
    </p>
  </DialogContent>
  }
;

const getAndroidPlayBookTTSDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold'}}>
    안드로이드 Play북 TTS 시연
  </DialogTitle>
};

const getAndroidPlayBookTTSDialogContent = () => {
  return <DialogContent>
    <p className='flex justify-center'>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/skuAUpHGh0k?si=VrDPtQgT_fJTcJ3o"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </p>
    <p className='flex justify-center'>
      <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'} />
      <Link href="https://play.google.com/store/books/details?id=jHnqEAAAQBAJ"><span>Play북 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>
    </p>
    <p className={'mt-4 text-lg indent-2'}>
      안드로이드 Play북의 "소리내어 읽기" 기능은, 안드로이드의 "글자읽어주기" 기능을 활용합니다.
    </p>
  </DialogContent>
};

const getIphonePlayBookTTSDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold'}}>
    아이폰 Play북 TTS 시연
  </DialogTitle>
};

const getIphonePlayBookTTSDialogContent = () => {
  return <DialogContent>
    <p className='flex justify-center'>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/tdoJBmIkKjs?si=sHB2WkE0_unH_xWg"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </p>
    <p className='flex justify-center'>
      <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
      <Link href="https://play.google.com/store/books/details?id=jHnqEAAAQBAJ"><span>Play북 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>
    </p>
    <p className={'mt-4 text-lg indent-2'}>
      아이폰 Play북은 "소리내어 읽기" 기능이 없습니다. 한 화면에 있는 글자만 읽어주는, 아이폰의 "화면읽어주기" 기능을 활용합니다. 이를 위한 부록 페이지를 활용합니다.
    </p>
  </DialogContent>
};


const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default SelfAffirmationView;
