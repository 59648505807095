import qs from 'qs';
import {compressAsync, decompressAsync} from 'lzutf8';

const parseQueryString = () => {
  return qs.parse(window.location.search.replace('?', ''));
};

const patchQueryString = (newQuery: any) => {
  const originQuery = parseQueryString();
  const merged = { ...originQuery, ...newQuery };
  const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + qs.stringify(merged);
  window.history.pushState({path:newUrl},'',newUrl);
  return merged;
};

const encodeCompressedUrlSafe = async (origin: string): Promise<string> => {
  return await new Promise((resolve, reject) => {
    compressAsync(origin, {outputEncoding: 'Base64'}, function (result, error) {
      if (error) reject(error);
      else resolve(result);
  })});
}

const decodeCompressedUrlSafe = async (encoded: string): Promise<string> => {
  return await new Promise((resolve, reject) => {
    decompressAsync(encoded, {inputEncoding: 'Base64'}, function (result, error) {
      if (error) reject(error);
      else resolve(result);
    })});
}

export {parseQueryString, patchQueryString, encodeCompressedUrlSafe, decodeCompressedUrlSafe};
