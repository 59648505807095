import axios from "axios";
import {IQuizStore, ISolvingProgressStore, IQuiz, ISolved, ISolvingProgress} from "@/util/service/quiz/IQuiz";
import {getRandomInt} from "@/util/common/random";
import {addIndexedDBItem, getIndexedDBItem, getIndexedDBList, updateIndexedDBItem} from "@/util/common/web-storage";
import {isEmpty} from "@/util/common/handle-object";
import {formatDateStr} from "@/util/common/datetime";

const INDEXED_DB_NAME = 'MippumQuizDB';
// const INDEXED_DB_VERSION = 1;
const INDEXED_DB_STORE_NAME_QUIZ = 'QuizStore';
const INDEXED_DB_STORE_NAME_SOLVING_PROGRESS = 'SolvingProgressStore';


const SAMPLE_QUIZZES = [{
    name: '수도 퀴즈',
    url: process.env.PUBLIC_URL + '/content/service/quiz/samples/country-capital-quiz.json',
},{
    name: '맞춤법 퀴즈',
    url: process.env.PUBLIC_URL + '/content/service/quiz/samples/korean-spelling.mpqz.json',
},{
    name: '사칙연산 암산훈련',
    url: process.env.PUBLIC_URL + '/content/service/quiz/samples/mental-arithmetic.mpqz.json',
},{
    name: '영문 시제 퀴즈',
    url: process.env.PUBLIC_URL + '/content/service/quiz/samples/english-tense-grammar.mpqz.json',
},{
    name: '유명 철학 문제',
    url: process.env.PUBLIC_URL + '/content/service/quiz/samples/famous-philosophy.mpqz.json',
}];

const loadSampleQuiz = async (url:string|null=null): Promise<IQuiz> => {

    try {
        if(url){
            const response = await axios.get(url);
            return response.data;
        } else {
            const sampleIndex = getRandomInt(SAMPLE_QUIZZES.length-1);
            const response = await axios.get(SAMPLE_QUIZZES[sampleIndex].url);
            return response.data;
        }
    } catch(err) {
        console.log("Error >>", err);
        throw 'LoadSampleQuiz Error';
    }
}

const loadQuizById = async (id: number): Promise<IQuiz> => {
    try {
        const quizStore = await getIndexedDBItem(INDEXED_DB_NAME, INDEXED_DB_STORE_NAME_QUIZ, id);
        return quizStore.quiz;
    } catch(err) {
        console.log("Error >>", err);
        throw 'loadQuizById Error';
    }
}

const loadQuizHistory = async (): Promise<{ id: number, quizTitle: string, createdAt: string }[]> => {
    try { // 전체 퀴즈를 다 불러오는 것이 부담이 되면 history db 를 따로 생성하는 것이 필요.
        const quizStores: IQuizStore[] = await getIndexedDBList(INDEXED_DB_NAME, INDEXED_DB_STORE_NAME_QUIZ, 10);

        return quizStores.map((quizStore) => {
            return {id: quizStore.id,
                quizTitle: isEmpty(quizStore.quiz.quizTitle) ? 'No Title' : quizStore.quiz.quizTitle!,
                createdAt: formatDateStr(quizStore.createdAt) };
        });
    } catch(err) {
        console.log("Error >>", err);
        throw 'loadQuizHistory Error';
    }
}

const saveQuiz = async (quiz: IQuiz): Promise<number> => {
    const quizStore = {quiz: quiz, createdAt: new Date(), updatedAt: new Date()};
    return await addIndexedDBItem(INDEXED_DB_NAME, INDEXED_DB_STORE_NAME_QUIZ, quizStore);
}

const loadSolvingProgressStore = async (id: number): Promise<ISolvingProgressStore> => {
    return await getIndexedDBItem(INDEXED_DB_NAME, INDEXED_DB_STORE_NAME_SOLVING_PROGRESS, id);
}

const updateSolvingProgressStore = async (id: number, solvingProgress: ISolvingProgress) => {
    const now = new Date();
    const solvingProgressStore = await loadSolvingProgressStore(id) ?? { createdAt: now };
    solvingProgressStore.solvingProgress = solvingProgress;
    solvingProgressStore.updatedAt = now;
    await updateIndexedDBItem(INDEXED_DB_NAME, INDEXED_DB_STORE_NAME_SOLVING_PROGRESS, id, solvingProgressStore);
}

export {loadSampleQuiz, SAMPLE_QUIZZES, saveQuiz, loadQuizById, loadQuizHistory, loadSolvingProgressStore, updateSolvingProgressStore};
