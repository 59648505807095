import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React from "react";
import styled from "styled-components";

const QuizHintDialog = (props: {
    setIsOpened: Function,
    htmlInner: string,
}) => {

    return (<Dialog open={true}
                    fullScreen={false}
                    sx={{pr: 0, pl: 1}}>
        <TitleContainer>
            <ArrowBackIosNewIcon sx={{mr: 0}} onClick={() => {
                props.setIsOpened(false);
            }}/>
            <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
                힌트
            </DialogTitle>

        </TitleContainer>
        <DialogContent className={'ml-2 p-0'} sx={{paddingBottom: 1}}>
            <HtmlInner dangerouslySetInnerHTML={{__html: props.htmlInner}} />
        </DialogContent>
    </Dialog>);
}


const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1px;
`;


const HtmlInner = styled('div')`
    margin-top: 1px;
    margin-bottom: 50px;
    text-indent: 10px;
    p {
        margin: 1em;
    }
    ul {
        list-style: '- ';
        padding-left: 20px;
    }
`;


export { QuizHintDialog };
