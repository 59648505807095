import React from "react";
import {Box} from "@mui/material";
import MippumAppBar from "@/components/common/MippumAppBar";
import MippumDrawer from "@/components/common/MippumDrawer";
import {styled} from "@mui/material/styles";
import QuizView from "@/views/service/QuizView";

const drawerWidth = 240;

const Page = () => {
  const portrait = window.innerWidth < 1100;
  const [drawerOpen, setDrawerOpen] = React.useState(!portrait);

  return (<Box sx={{display:'flex'}}>
    <MippumAppBar title='퀴즈' drawerOpen={drawerOpen} toggleDrawer={()=>{setDrawerOpen(!drawerOpen)}} />
    <MippumDrawer drawerOpen={drawerOpen} />
    <CollapsedView open={drawerOpen}>
      <AppBarGap />
      <QuizView />
    </CollapsedView>
  </Box>)
}

export {Page};

const CollapsedView = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBarGap = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

