import React, {useEffect, useState} from "react";

import {
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  FormControl, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemButton, ListItemText, Chip,
} from '@mui/material';

import wait from "waait";
import {castToMPVoice, MPVoice, sortVoices} from "@/util/tts/speech-synthesis";
import {
  addHistoryListeningTrainerStorage, IHistory, LISTENING_TRAINER_SAMPLES,
  loadListeningTrainerStorage,
  loadSampleContent,
} from "@/util/service/listening-trainer/content";
import {
  decodeCompressedUrlSafe,
  encodeCompressedUrlSafe,
  parseQueryString,
  patchQueryString
} from "@/util/common/query-string";

import HistoryIcon from '@mui/icons-material/History';

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import {isEmpty, isNotNil} from "@/util/common/handle-object";
import {getDeviceStored} from "@/util/common/device";
import SpeakingDialog from "@/components/service/listening-trainer/SpeakingDialog";

const ListeningTrainerView = () => {

  const [voices, setVoices] = useState<Array<MPVoice>>([]);
  const [selectedVoiceIndex, setSelectedVoiceIndex] = useState<number>(0);
  const [contentDetail, setContentDetail] = useState<string>('');
  const [repeatTotal, setRepeatTotal] = useState<number>(1);
  const [historyDialogOpen, setHistoryDialogOpen] = useState<boolean>(false);
  const [history, setHistory] = useState<Array<IHistory>>([]);
  const [noVoiceDialogOpen, setNoVoiceDialogOpen] = useState<boolean>(false);
  const [speakingDialogOpen, setSpeakingDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    fetch().then();
    return () => { // clear up code
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = async () => {
    const stored = loadListeningTrainerStorage();
    if(stored['history']) {
      setHistory(stored['history']);
    }
    // console.log(stored);
    const query = parseQueryString();
    if('contentDetail' in query
        && 'voiceLang' in query
        && typeof query['contentDetail'] === 'string'
        && typeof query['voiceLang'] === 'string' ) {
      const encodedContentDetail = await decodeCompressedUrlSafe(query['contentDetail']);
      setContentDetail(encodedContentDetail);
      await loadVoices('ko-KR', query['voiceLang']);
    } else {
      const sampleContent = await loadSampleContent();
      setContentDetail(sampleContent.detail);
      await loadVoices('ko-KR', sampleContent.lang);
    }
  }

  const loadVoices = async (localLang = 'ko-KR', contentLang: string = 'en-US') => {

    if(isNotNil(window.speechSynthesis)) {
      const delays = [1, 10, 100, 1000, 4000];
      for(const delay of delays) {
        await wait(delay);
        const voices = speechSynthesis.getVoices();
        if (voices.length > 0)  {
          const arrangedVoices: Array<MPVoice> = voices.map(
            (voice, index) => {
              return castToMPVoice(voice, index);
            }).sort((a, b) => {
            return sortVoices(a, b, localLang, contentLang);
          });
          setVoices(arrangedVoices);
          setSelectedVoiceIndex(0);
          return;
        }
      }
    }

    const deviceStored = getDeviceStored();
    const voices = isEmpty(deviceStored.tts) ? [] : deviceStored.tts!.voices;
    if (voices.length > 0) {
      const arrangedVoices: Array<MPVoice> = voices.map(
        (voice, index) => {
          return {
            originIndex: voice.originIndex,
            name: voice.name,
            lang: voice.lang,
            identifier: voice.identifier,
            type: 'AndroidTTS',
          };
        }).sort((a, b) => {
        return sortVoices(a, b, localLang, contentLang);
      })
      setVoices(arrangedVoices);
      setSelectedVoiceIndex(0);
      return;
    }

    setNoVoiceDialogOpen(true); // voices are empty
  };

  const speak = () => {
    // console.log('speak');
    if(voices.length === 0) {
      setNoVoiceDialogOpen(true);
      return;
    }

    setQueryStringAndHistory().then();

    if(voices[selectedVoiceIndex].type === 'AndroidTTS') {
      const selectedVoice = voices[selectedVoiceIndex];
      const reactNativeWebView = (window as any).ReactNativeWebView;

      const webviewMessage = {
        type: 'listening-trainer.speak-open',
        message: {
          text: contentDetail,
          voiceName: selectedVoice.name,
          voiceIndex: selectedVoice.originIndex,
          voiceIdentifier: selectedVoice.identifier,
          repeatTotal: repeatTotal,
        }
      };
      isNotNil(reactNativeWebView) && reactNativeWebView.postMessage(JSON.stringify(webviewMessage));
      return;
    }

    setSpeakingDialogOpen(true);
  }

  const setQueryStringAndHistory = async () => {
    const contentDetailEncoded = await encodeCompressedUrlSafe(contentDetail);
    patchQueryString({contentDetail: contentDetailEncoded, voiceLang: voices[selectedVoiceIndex].lang});
    addHistoryListeningTrainerStorage({detail: contentDetail, lang: voices[selectedVoiceIndex].lang});
  }

  return (<div>
    <div>
      <Button variant="contained"
              onClick={() => speak()}
              sx={{marginRight: '4px', marginBottom: '15px'}}>듣기</Button>

      <FormControl>
        <InputLabel id="repeat-total-label">총 반복</InputLabel>
        <Select
            labelId={'repeat-total-label'}
            value={repeatTotal}
            label="Voice"
            className={'h-10 w-24 mr-2'}
            autoWidth
            onChange={(event) => {
              if (typeof event.target.value === 'number') setRepeatTotal(event.target.value);
            }}
        >
          <MenuItem key='repeat-1' value={1}>1</MenuItem>
          <MenuItem key='repeat-3' value={3}>3</MenuItem>
          <MenuItem key='repeat-5' value={5}>5</MenuItem>
          <MenuItem key='repeat-10' value={10}>10</MenuItem>
          <MenuItem key='repeat-20' value={20}>20</MenuItem>
          <MenuItem key='repeat-50' value={50}>50</MenuItem>
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel id="voice-select-label">Voice</InputLabel>
        <Select
          labelId={'voice-select-label'}
          value={selectedVoiceIndex}
          label="Voice"
          className={'h-10 w-48 mr-2'}
          autoWidth
          onChange={(event) => {
            setSelectedVoiceIndex(Number(event.target.value));
          }}>
          {voices.map((voice, index) => (
            <MenuItem key={'voice-'+index} value={index}>{voice.name} [{voice.lang}]</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button variant="contained"
              sx={{marginTop: '0px', marginBottom: '15px'}}
              onClick={() => {
                const stored = loadListeningTrainerStorage();
                setHistory(stored.history);
                setHistoryDialogOpen(true);
              }}><HistoryIcon /></Button>
    </div>
    <div>
      <TextField label="편집"
                 multiline
                 rows={20}
                 fullWidth
                 sx={{
                   marginTop: '10px',
                 }}
                 onChange={(event) => {
                   setContentDetail(event.target.value);
                 }}
                 key={'editor'}
                 value={contentDetail}/>
    </div>

    <Dialog open={historyDialogOpen}
            sx={{pr: 1, pl: 1}}
            PaperProps={{style: {minHeight: '90%', maxHeight: '90%'}}}>
      <TitleContainer>
        <ArrowBackIosNewIcon sx={{mr: 0, ml:2}} onClick={() => setHistoryDialogOpen(false)}/>
        <DialogTitle sx={{fontWeight: 'bold'}}>
          듣기 히스토리
        </DialogTitle>
      </TitleContainer>
      <DialogContent>
        <List>
          {history.map((oneHistory, index) => (<>
            <Divider />
            <ListItem key={`history-${index}`} disablePadding>
              <ListItemButton onClick={async ()=> {
                    setContentDetail(oneHistory.content.detail);
                    await loadVoices('ko-KR', oneHistory.content.lang);
                    setHistoryDialogOpen(false);
                  }}>
                <ListItemText primary={oneHistory.content.detail.slice(0, 100) + ' ...'}
                  secondary={ <div className={'mt-2'}>
                                <Chip className={'mr-2'} label={oneHistory.content.lang} variant="outlined" size="small" />
                                {oneHistory.created}
                              </div>} />
              </ListItemButton>
            </ListItem>
          </>))}
          {LISTENING_TRAINER_SAMPLES.map((sample, index) => (<>
            <Divider />
            <ListItem key={`sample-${index}`} disablePadding>
              <ListItemButton onClick={async ()=> {
                const sampleContent = await loadSampleContent(sample.url);
                setContentDetail(sampleContent.detail);
                await loadVoices('ko-KR', sampleContent.lang);
                setHistoryDialogOpen(false);
              }}>
                <ListItemText primary={sample.short + ' ...'}
                              secondary={ <div className={'mt-2'}>
                                <Chip className={'mr-2'} label={sample.lang} variant="outlined" size="small" />
                                <Chip className={'mr-2'} label={'sample'} variant="outlined" size="small" />
                                <Chip className={'mr-2'} label={sample.nameKo} variant="outlined" size="small" />
                              </div>} />
              </ListItemButton>
            </ListItem>
          </>))}
        </List>
      </DialogContent>
    </Dialog>

    <Dialog open={noVoiceDialogOpen}>
      <TitleContainer>
        <ArrowBackIosNewIcon sx={{mr: 0, ml:2}} onClick={() => setNoVoiceDialogOpen(false)}/>
        <DialogTitle sx={{fontWeight: 'bold'}}>
          TTS Load Error
        </DialogTitle>
      </TitleContainer>
      <DialogContent>
        <div>
          <p>TTS 목소리 로딩에 실패하였습니다.</p>
          <p>카카오톡 웹과 같은 특정 브라우저에서 발생할 수 있습니다.</p>
          <p>URL을 복사하여 다른 브라우저에서 열어보세요.</p>
        </div>
        <TextField disabled
                   size={'small'}
                   sx={{marginTop: '10px', marginRight: '10px'}}
                   label="url"
                   value={window.location.href} />
        <Button sx={{marginTop: '11px'}}
                onClick={() => navigator.clipboard.writeText(window.location.href)}
                variant="outlined">copy</Button>
      </DialogContent>
    </Dialog>

    {speakingDialogOpen? <SpeakingDialog
        isOpened={speakingDialogOpen}
        setIsOpened={setSpeakingDialogOpen}
        text={contentDetail}
        voiceIndex={voices[selectedVoiceIndex] ? voices[selectedVoiceIndex].originIndex : -1}
        repeatTotal={repeatTotal} /> : ''}

  </div>)
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


export default ListeningTrainerView;
