import store from 'store2';

const INDEXED_DB_QUIZ = 'MippumQuizDB';
const INDEXED_DB_QUIZ_VERSION = 1;
const INDEXED_DB_STORE_QUIZ = 'QuizStore';
const INDEXED_DB_STORE_SOLVING_PROGRESS = 'SolvingProgressStore';

const STORE_CONFIGS = [{
  dbName: INDEXED_DB_QUIZ,
  dbVersion: 1,
  storeName: INDEXED_DB_STORE_QUIZ,
}, {
  dbName: INDEXED_DB_QUIZ,
  dbVersion: 1,
  storeName: INDEXED_DB_STORE_SOLVING_PROGRESS,
}];

const getLocalStorage = (key: string) => {
  const stored = store.get(key);
  return stored;
}

const setLocalStorage = (key: string, data: any) => {
  store.set(key, data);
}

const initDB = (dbName: string): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    if(dbName === INDEXED_DB_QUIZ) {
      const request = indexedDB.open(INDEXED_DB_QUIZ, INDEXED_DB_QUIZ_VERSION);

      request.onsuccess = (event) => { resolve((event.target as IDBOpenDBRequest).result); };
      request.onerror = (event) => { reject((event.target as IDBOpenDBRequest).error); };

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        console.log('index db. onupgradeneeded. oldVersion: ' + event.oldVersion);

        STORE_CONFIGS
            .filter((config) => config.dbName === INDEXED_DB_QUIZ)
            .filter((config) => config.dbVersion > event.oldVersion)
            .filter((config) => config.dbVersion <= INDEXED_DB_QUIZ_VERSION)
            .forEach((config) => {
              console.log('createObjectStore. Store name: ' + config.storeName);
              db.createObjectStore(config.storeName, {keyPath: 'id', autoIncrement: true});
            });
      }
    }
  });
};

const addIndexedDBItem = async (dbName: string, storeName: string, item: any, id: number|null = null) => {
  const db = await initDB(dbName);
  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);

  return new Promise<number>((resolve, reject) => {
    if(id === null) {
      const request = store.add(item);
      request.onsuccess = () => resolve(request.result as number);
      request.onerror = () => reject(request.error);
    } else {
      const request = store.add(item, id);
      request.onsuccess = () => resolve(request.result as number);
      request.onerror = () => reject(request.error);
    }
  });
};

const updateIndexedDBItem = async (dbName: string, storeName: string, id: number, item: any) => {
  const db = await initDB(dbName);
  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);

  return new Promise<void>((resolve, reject) => {
    const request = store.put(Object.assign({id: id}, item));
    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};

const deleteIndexedDBItem = async (dbName: string, storeName: string, id: number) => {
  const db = await initDB(dbName);
  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);

  return new Promise<void>((resolve, reject) => {
    const request = store.delete(id);
    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};

const getIndexedDBItem = async (dbName: string, storeName: string, id: number): Promise<any> => {
  const db = await initDB(dbName);
  const transaction = db.transaction(storeName, 'readonly');
  const store = transaction.objectStore(storeName);

  return new Promise<any>((resolve, reject) => {
    const request = store.get(id);
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

const getIndexedDBList = async (dbName: string, storeName: string, limit: number): Promise<any[]> => {
  const db = await initDB(dbName);
  const transaction = db.transaction(storeName, 'readonly');
  const store = transaction.objectStore(storeName);

  return new Promise<any[]>((resolve, reject) => {
    const items: any[] = [];
    const request = store.openCursor(null, 'prev');

    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
      if(items.length >= limit ) {
        resolve(items);
      } else if (cursor) {
        items.push(cursor.value);
        cursor.continue();
      } else {
        resolve(items);
      }
    };

    request.onerror = () => reject(request.error);
  });
};


export {getLocalStorage, setLocalStorage, addIndexedDBItem, updateIndexedDBItem, deleteIndexedDBItem, getIndexedDBItem, getIndexedDBList};
