import React from "react";
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Page = () => {
    return (<Box>
        <Typography className="text-center pt-10" variant="h4">
            죄송합니다. 해당 페이지를 찾을 수 없습니다.
        </Typography>
        <Typography className="text-center" variant="h6">
            Sorry, the page you're looking for doesn't exist.
        </Typography>
        <Typography className="text-center pt-10">
            <Button variant="contained"><Link to="/">Home 으로 가기</Link></Button>
        </Typography>
    </Box>)
}

export { Page };
